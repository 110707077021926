<template>
  <el-card class="page-card">
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :part="part"
      :req="tableFunReq"
      :isOrderKey="isOrderKey"
      @edit="edit"
      @detail="detail"
      @hidden="hidden"
      @backflow="backflow"
    />
  </el-card>
</template>
  <script>
import SuiList from "@/components/s-ui/list";
import { EditSpu, GetOrderLogisticListbyVendor } from "@/utils/api";
import { formatDataTime } from "@/utils";
export default {
  components: { SuiList },
  data() {
    return {
      editing: false,
      input3: "",
      inputData: "",
      max: 10,
      name: "",
      part: 1,
      isOrderKey: false,
      tableColumn: [
        // 订单号
        {
          name: "order.orderNo",
          label: "order.orderNo",
        },
        // 用户名
        {
          name: "order.username",
          label: "content.order.username",
        },
        // 联系电话
        {
          name: "order.phone",
          label: "inquiry.phone",
        },
        // 联系邮箱
        {
          name: "order.email",
          label: "account.email",
        },
        // 城市
        {
          name: "order.country",
          label: "content.order.country",
        },
        // 状态
        {
          name: "order.status",
          label: "productManagement.status",
        },
        // 数量
        {
          name: "order.pcs",
          label: "content.order.pcs",
        },
        // 体积
        {
          name: "order.cbm",
          label: "content.order.cbm",
        },
        // 总金额
        // {
        //   name: "order.total",
        //   label: "content.order.total",
        // },
        // 创建时间
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      tableFun: GetOrderLogisticListbyVendor,
      tableFunReq: {
        pageSize: this.max || 10,
      },
      // 表格中的分类
      tableDataRefactor: (list) => {
        console.log("list", list);

        return list.map((v) => {
          return {
            ...v,
            categoryNameList: v.categoryList
              .map((c) => c.categoryName)
              .join(" -> "),
          };
        });
      },
      count: 0,
    };
  },
  methods: {
    searchData() {
      this.$refs.list.handleSearch(this.input3);
    },
    // 编辑
    edit(item) {
        this.$router.push({
          path: "/logisticstracking/edit",
          query: {
            id: item.id,
          },
        });
    },
    // 详情
    detail(item) {
      console.log("详情", item);
      this.$router.push({
        path: "/logisticstracking/detail",
        query: {
          id: item.id,
        },
      });
    },
    // 隐藏
    hidden(e, rowData, index) {
      console.log("hidden:", e, rowData, index);
      this.iEditBanner(rowData.id, { hidden: e }).then(() => {
        this.$message({
          type: "success",
          message: this.$t("common.optSuccess"),
        });
        this.$refs["list"].getList();
      });
    },
    iEditBanner(id, params) {
      return EditSpu({
        id,
        ...params,
      });
    },
    // 分页
    backflow(count) {
      this.count = count;
    },
  },
};
</script>
  
  <style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>